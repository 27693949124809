/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { ReactNode } from "react";

// next
import dynamic from 'next/dynamic';

// PRO
const MDBox = dynamic(() => import('components/MDBox'), { loading: () => null });
const MDTypography = dynamic(() => import('components/MDTypography'), { loading: () => null });

// Declaring props types for DefaultInfoCard
interface Props {
  icon: ReactNode;
  title: string;
  description?: string;
  value?: string | number;
  [key: string]: any;
}

function DefaultInfoCard({
  icon,
  title,
  description,
}: Props): JSX.Element {
  return (
    <MDBox lineHeight={1} p={0} textAlign="left">
      <MDBox sx={{ fontSize: 30 }}>
        {icon}
      </MDBox>
      <MDTypography
        display="block"
        variant="5"
        fontWeight="bold"
        mt={2}
        mb={1.5}
      >
        {title}
      </MDTypography>
      <MDTypography
        display="block"
        variant='body2'
        color="text"
        pr={6}
        pl={0}
        fontWeight="regular"
      >
        {description}
      </MDTypography>
    </MDBox>
  );
}

export default DefaultInfoCard;
